/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState, useEffect} from 'react'
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import {KTSVG, useDebounce} from '../../../helpers'
import { listAll } from '../../../../api/simulationApi';
import { ViewSimulationDetails } from '../../modals/view-details-simulations/ViewDetailsSimulation';
import { EditSimulation } from '../../modals/edit-simulation/EditSimulation';
//import { DeleteSimulation } from '../../modals/delete-simulation/DeleteSimulation';
import { PushToMontaggio } from '../../modals/push-to-montaggio/PushToMontaggio';
import { PushToSviluppo } from '../../modals/push-to-sviluppo/PushToSviluppo';
import { PushToTesting } from '../../modals/push-to-testing/PushToTesting';
import { PushToDeploying } from '../../modals/push-to-deploying/PushToDeploying';
import { PushToVendibile } from '../../modals/push-to-vendibile/PushToVendibile';
import { PushToManutenzione } from '../../modals/push-to-manutenzione/PushToManutenzione';
import {UsersListSearchComponent} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import {UsersListPagination} from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination'

import './StatoSimulazioniWidget.css';

import dayjs from 'dayjs';
import 'dayjs/locale/it';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('it');

type MyData = {
  ID_Simulazione: number
  Nome: string
  Tipologia: string
  Modalita: string
  Periodo_consegna_stimato: string
  Stato: string
  Responsabile_Nome: string
  Responsabile_Cognome: string
};

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type Props = {
  className: string
}

const StatoSimulazioniWidget: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]); // New state for filtered data
  const [searchTerm, setSearchTerm] = useState<string>(''); // New state for search term
  const debouncedSearchTerm = useDebounce(searchTerm, 150); // Debounce search term
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'Nome', direction: 'asc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Numero di elementi per pagina

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [showEditSimulation, setShowEditSimulation] = useState(false);
  const [showDeleteSimulation, setShowDeleteSimulation] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [simulationStatus, setSimulationStatus] = useState<string>('Pianificazione');
  const [selectedSimulationStatus, setSelectedSimulationStatus] = useState<string | null>(null);

  const [showPushToMontaggio, setShowPushToMontaggio] = useState(false);
  const [showPushToSviluppo, setShowPushToSviluppo] = useState(false);
  const [showPushToTesting, setShowPushToTesting] = useState(false);
  const [showPushToDeploying, setShowPushToDeploying] = useState(false);
  const [showPushToVendibile, setShowPushToVendibile] = useState(false);
  const [showPushToManutenzione, setShowPushToManutenzione] = useState(false);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const handleRowClick = (action: string, id: number) => {
    setSelectedRecordId(id);
    if (action === 'view-details') {
      setShowModal(true);
    } else if (action === 'edit-simulation') {
      setShowEditSimulation(true);
    } else if (action === 'delete-simulation') {
      setShowDeleteSimulation(true);
    } else if(action === 'push-to-montaggio' && simulationStatus === 'Pianificazione') {
      setShowPushToMontaggio(true);
    } else if(action === 'push-to-sviluppo' && simulationStatus === 'Montaggio') {
      setShowPushToSviluppo(true);
    } else if(action === 'push-to-testing' && simulationStatus === 'Sviluppo') {
      setShowPushToTesting(true);
    } else if(action === 'push-to-deploying' && simulationStatus === 'Testing') {
      setShowPushToDeploying(true);
    } else if(action === 'push-to-vendibile' && simulationStatus === 'Deploying') {
      setShowPushToVendibile(true);
    } else if(action === 'push-to-manutenzione' && simulationStatus === 'Vendibile') {
      setShowPushToManutenzione(true);
    } else if(action === 'push-to-vendibile' && simulationStatus === 'Manutenzione') {
      setShowPushToVendibile(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseEditSimulation = () => {
    setShowEditSimulation(false);
  };

  /*const handleCloseDeleteSimulation = () => {
    setShowDeleteSimulation(false);
  };*/

  const handleClosePushToMontaggio = () => {
    setShowPushToMontaggio(false);
  };

  const handleClosePushToSviluppo = () => {
    setShowPushToSviluppo(false);
  };

  const handleClosePushToTesting = () => {
    setShowPushToTesting(false);
  };

  const handleClosePushToDeploying = () => {
    setShowPushToDeploying(false);
  };

  const handleClosePushToVendibile = () => {
    setShowPushToVendibile(false);
  };

  const handleClosePushToManutenzione = () => {
    setShowPushToManutenzione(false);
  };

  const [stateCounts, setStateCounts] = useState<{ [key: string]: number }>({});
  const [allData, setAllData] = useState<MyData[]>([]);

  const fetchData = async () => {
    try {
      let response = await listAll();
  
      // Calcola il conteggio per ogni stato
      const counts: { [key: string]: number } = {};
      response.forEach((order: MyData) => {
        counts[order.Stato] = (counts[order.Stato] || 0) + 1;
      });
  
      // Imposta i conteggi
      setStateCounts(counts);

      // Imposta tutti i dati
      setAllData(response);
  
      if(simulationStatus !== null) {
        response = response.filter((order: MyData) => order.Stato === simulationStatus);
      }
  
      // Imposta i dati
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [simulationStatus]);

  // useEffect per gestire la barra di ricerca
  useEffect(() => {
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase();
      const filtered = data.filter(order => {
        return Object.values(order).some(val => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm);
          }
          return false;
        });
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [debouncedSearchTerm, data]);

  // Nuova funzione per gestire il cambio dello stato della simulazione
  const handleSimulationStatusChange = (status: string) => {
    setCurrentPage(1);
    setSimulationStatus(status);
  };

  const changeSort = (key: keyof MyData) => {
    setSortConfig(currentSortConfig => {
      if (currentSortConfig.key === key) {
        return { key, direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData); // Usa filteredData qui
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  const totalReadyToUseSimulations = useMemo(() => {
    return allData.filter(order => order.Tipologia === "Pronto uso").length;
  }, [allData]);  

  const totalCustomSimulations = useMemo(() => {
    return allData.filter(order => order.Tipologia === "Personalizzato").length;
  }, [allData]);
  
  const totalSinglePlayerSimulations = useMemo(() => {
    return allData.filter(order => order.Modalita === "Individuale").length;
  }, [allData]);
  
  const totalMultiplayerSimulations = useMemo(() => {
    return allData.filter(order => order.Modalita === "Cooperativo").length;
  }, [allData]);
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Barra ricerca */}
      <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Total Simulations */}
        <div className='me-10'>
          <span className='fw-bold'>Pronti all'uso: </span>{totalReadyToUseSimulations}
        </div>
	      <div className='me-10'>
          <span className='fw-bold'>Personalizzati: </span>{totalCustomSimulations}
        </div>
        <div className='me-10'>
          <span className='fw-bold'>| </span>
        </div>
        <div className='me-10'>
          <span className='fw-bold'>Individuale: </span>{totalSinglePlayerSimulations}
        </div>
        <div className='me-10'>
          <span className='fw-bold'>Cooperativo: </span>{totalMultiplayerSimulations}
        </div>
        {/* end::Total Simulations */}
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3 no-margin' onClick={exportDataToCSV}>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>
        {/* end::Export */}
      </div>
      {/* end::Card toolbar */}
      </div>
      {/* end::Barra ricerca */}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Stato simulazioni</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-differito fw-bold px-4 me-1 ${simulationStatus === 'Pianificazione' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Pianificazione')}
              >
                Pianificazione ({stateCounts['Pianificazione'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-ricevuto fw-bold px-4 me-1 ${simulationStatus === 'Montaggio' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Montaggio')}
              >
                Montaggio ({stateCounts['Montaggio'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-inlavorazione fw-bold px-4 me-1 ${simulationStatus === 'Sviluppo' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Sviluppo')}
              >
                Sviluppo ({stateCounts['Sviluppo'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-pronto fw-bold px-4 me-1 ${simulationStatus === 'Testing' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Testing')}
              >
                Testing ({stateCounts['Testing'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-spedito fw-bold px-4 me-1 ${simulationStatus === 'Deploying' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Deploying')}
              >
                Deploying ({stateCounts['Deploying'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-attivo fw-bold px-4 me-1 ${simulationStatus === 'Vendibile' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Vendibile')}
              >
                Vendibile ({stateCounts['Vendibile'] || 0})
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-scaduto fw-bold px-4 me-1 ${simulationStatus === 'Manutenzione' ? 'active' : ''}`}
                onClick={() => handleSimulationStatusChange('Manutenzione')}
              >
                Manutenzione ({stateCounts['Manutenzione'] || 0})
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px th-padding text-muted fw-bold text-head' onClick={() => changeSort('Nome')}>Ragione sociale</th>
                    <th className='p-0 min-w-140px text-end th-padding text-muted fw-bold text-head' onClick={() => changeSort('Tipologia')}>Tipologia cessione</th>
                    <th className='p-0 min-w-140px text-end th-padding text-muted fw-bold text-head' onClick={() => changeSort('Modalita')}>Modalità</th>
                    {['Pianificazione','Montaggio','Sviluppo','Testing','Deploying'].includes(simulationStatus) && (
                    <th className='p-0 min-w-110px text-end th-padding text-muted fw-bold text-head' onClick={() => changeSort('Periodo_consegna_stimato')}>Consegna stimata</th>
                    )}
                    {/*<th className='p-0 min-w-50px text-end th-padding text-muted fw-bold text-head' onClick={() => changeSort('Stato')}>Stato</th>*/}
                    <th className='p-0 min-w-50px text-end th-padding text-muted fw-bold text-head'>Responsabile</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {sortedData.slice(start, end).map(item => (
                    <tr key={item.ID_Simulazione}>
                      <td>
                      </td>
                      <td style={{ fontSize: "1.15rem" }}>{item.Nome}</td>
                      <td className='text-end text-body fw-semibold fw-underline'>{item.Tipologia}</td>
                      <td className='text-end text-body fw-semibold fw-underline'>{item.Modalita}</td>
                      {['Pianificazione','Montaggio','Sviluppo','Testing','Deploying'].includes(simulationStatus) && (
                      <td className='text-end text-body fw-semibold'>{item.Periodo_consegna_stimato}</td>
                      )}
                      {/*<td className='text-end text-body fw-semibold'>{item.Stato}</td>*/}
                      <td className='text-end text-body fw-semibold'>{item.Responsabile_Nome} {item.Responsabile_Cognome}</td>
                      <td className='text-end'>
                        <div className='btn-group'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen052.svg'
                              className='svg-icon-2'
                            />
                          </button>
                          <ul className='dropdown-menu custom-dropdown-menu'>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('view-details', item.ID_Simulazione)}>
                                Dettagli
                              </a>
                            </li>
                            <li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('edit-simulation', item.ID_Simulazione)}>
                                Modifica
                              </a>
                            </li>
                            {/*<li className='menu-item px-3'>
                              <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('delete-order', item.ID_Simulazione)}>
                                Elimina
                              </a>
                            </li>*/}
                            {item.Stato === 'Pianificazione' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-montaggio', item.ID_Simulazione)}>
                                  Passa a Montaggio
                                </a>
                              </li>
                            )}
                            {item.Stato === 'Montaggio' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-sviluppo', item.ID_Simulazione)}>
                                  Passa a Sviluppo
                                </a>
                              </li>
                            )}
                            {item.Stato === 'Sviluppo' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-testing', item.ID_Simulazione)}>
                                  Passa a Testing
                                </a>
                              </li>
                            )}
                            {item.Stato === 'Testing' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-deploying', item.ID_Simulazione)}>
                                  Passa a Deploying
                                </a>
                              </li>
                            )}
                            {(item.Stato === 'Deploying' || item.Stato === 'Manutenzione') && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-vendibile', item.ID_Simulazione)}>
                                  Passa a Vendibile
                                </a>
                              </li>
                            )}
                            {item.Stato === 'Vendibile' && (
                              <li className='menu-item px-3'>
                                <a className='dropdown-item menu-item px-3' onClick={() => handleRowClick('push-to-manutenzione', item.ID_Simulazione)}>
                                  Passa a Manutenzione
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
            <UsersListPagination
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
          {/* end::Tap pane */}

        </div>
      </div>
      {/* end::Body */}
      {selectedRecordId !== null && (
        <>
        <ViewSimulationDetails show={showModal} handleClose={handleCloseModal} simulationId={selectedRecordId} />
        <EditSimulation show={showEditSimulation} handleClose={handleCloseEditSimulation} simulationId={selectedRecordId} />
        {/*<DeleteSimulation show={showDeleteSimulation} handleClose={handleCloseDeleteSimulation} simulationId={selectedRecordId} />*/}
        <PushToMontaggio show={showPushToMontaggio} handleClose={handleClosePushToMontaggio} recordId={selectedRecordId} />
        <PushToSviluppo show={showPushToSviluppo} handleClose={handleClosePushToSviluppo} recordId={selectedRecordId} />
        <PushToTesting show={showPushToTesting} handleClose={handleClosePushToTesting} recordId={selectedRecordId} />
        <PushToDeploying show={showPushToDeploying} handleClose={handleClosePushToDeploying} recordId={selectedRecordId} />
        <PushToVendibile show={showPushToVendibile} handleClose={handleClosePushToVendibile} recordId={selectedRecordId} />
        <PushToManutenzione show={showPushToManutenzione} handleClose={handleClosePushToManutenzione} recordId={selectedRecordId} />
        </>
      )}
    </div>
  )
}

export {StatoSimulazioniWidget}
