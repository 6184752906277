import * as Yup from 'yup'

export interface ICreateGoggle {
    //Numero: string 
    //Data_acquisto: Date | null | string
    Seriale_scatola: string
    Seriale_visore: string
    COD_Visore: number | null
    //COD_Venditore: number | null
    COD_Categoria: number | null
}

export const createGoggleSchemas = Yup.object().shape({
  //Data_acquisto: Yup.date().nullable().max(new Date(), 'La data inserita non può essere successiva a quella odierna').label('DataAcquisto'),
  Seriale_scatola: Yup.string().nullable().length(14, 'Seriale scatola deve avere 14 cifre'),
  Seriale_visore: Yup.string().nullable().length(14, 'Seriale visore deve avere 14 cifre'),
  COD_Visore: Yup.number().nullable().required('Il codice visore è obbligatorio'),
  //COD_Venditore: Yup.number().nullable().required('Il codice venditore è obbligatorio'),
  COD_Categoria: Yup.number().nullable().required('Il codice categoria è obbligatorio'),
}).test('test-seriale', 'Inserire almeno uno dei seriali (scatola o visore)', function(value) {
  const { Seriale_scatola, Seriale_visore } = value;
  if (!Seriale_scatola && !Seriale_visore) {
      return this.createError({
          path: 'Seriale_scatola',
          message: 'Inserire almeno uno dei seriali (scatola o visore)',
      });
  }
  return true;
});

export const inits: ICreateGoggle = {
    //Numero: '',
    //Data_acquisto: '',
    Seriale_scatola: '',
    Seriale_visore: '',
    COD_Visore: null,
    //COD_Venditore: null,
    COD_Categoria: null,
}