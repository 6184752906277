/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import { updateAccount, goggleDetails } from '../../../../api/gogglesApi'  // <— IMPORTA QUI LA TUA FUNZIONE DI GET

type Props = {
  show: boolean
  handleClose: () => void
  recordId: number
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateAccount = ({ show, handleClose, recordId }: Props) => {
  const [email, setEmail] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  // Quando il modal diventa visibile e abbiamo un recordId, carichiamo i dati esistenti
  useEffect(() => {
    if (show && recordId) {
      fetchAccountData(recordId)
    }
  }, [show, recordId])

  const fetchAccountData = async (id: number) => {
    setLoading(true)
    try {
      const response = await goggleDetails(id)
      setEmail(response[0]?.Email)
      setPassword(response[0]?.Password)
    } catch (error) {
      console.error('Errore durante il recupero dei dati account:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateAccount = async () => {
    try {
      await updateAccount(recordId, email, password)
      // Resettiamo i campi
      setEmail(null)
      setPassword(null)
      // Chiudiamo il modal principale
      handleClose()
      // Mostriamo il modal di conferma
      setShowConfirmModal(true)
    } catch (error) {
      console.error(error)
    }
  }

  const closeConfirmModal = () => setShowConfirmModal(false)

  return createPortal(
    <>
      <Modal
        id="kt_modal_update_delivery_effective"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={show}
        onHide={handleClose}
      >
        <div className="modal-header">
          <h2>Aggiornamento account visore</h2>
          {/* begin::Close */}
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="text"
            id="email"
            value={email || ''}
            onChange={(event) => setEmail(event.target.value)}
            className="form-control form-control-lg form-control-solid"
          />
          <br />
          <br />
          <label htmlFor="password" className="form-label">
            Password:
          </label>
          <input
            type="text"
            id="password"
            value={password || ''}
            onChange={(event) => setPassword(event.target.value)}
            className="form-control form-control-lg form-control-solid"
          />
          <br />
          <br />
          <button className="btn btn-lg btn-light-primary me-3" onClick={handleUpdateAccount}>
            Invio
          </button>
        </div>
      </Modal>

      {/* Modal di conferma */}
      <Modal
        id="kt_modal_confirm_assign"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={showConfirmModal}
        onHide={closeConfirmModal}
      >
        <div className="modal-header">
          <h2>Aggiornamento account visore</h2>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>
        <div className="modal-body">
          <p>L'aggiornamento dell'account del visore è andato a buon fine!</p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-lg btn-light-primary me-3" onClick={closeConfirmModal}>
            Chiudi
          </button>
        </div>
      </Modal>
    </>,
    modalsRoot
  )
}

export { UpdateAccount }