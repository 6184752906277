/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import { pushToDeploying } from "../../../../api/simulationApi"

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

const modalsRoot = document.getElementById('root-modals') || document.body

const PushToDeploying = ({ show, handleClose, recordId }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  
  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleEndContract = async () => {
    if (selectedOption === 'Si') {
      try {
        await pushToDeploying(recordId);
        handleClose();
        setShowConfirmModal(true);
      } catch (error) {
        console.error(error);
      }
    } else if (selectedOption === 'No') {
      handleClose();
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_order_delivered"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Passa a Deploying</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-pronto" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body">
      <label className='d-flex align-items-center form-label mb-5 required'>
        Sei sicuro di voler passare questa simulazione alla fase di Deploying?
        </label>
          <div className='row mb-2' data-kt-buttons='true'>    
          <div className='col'>
                <input 
                    type="radio" 
                    id="Si" 
                    name="pushToDeploying" 
                    value="Si" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="Si" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>Si</span>
                </label>
              </div>

              <div className='col'>
                <input 
                    type="radio" 
                    id="No" 
                    name="pushToDeploying" 
                    value="No" 
                    onChange={event => handleRadioChange(event.target.value)}
                    className='btn-check'
                />
                <label htmlFor="No" className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'>
                  <span className='fw-bolder fs-3'>No</span>
                </label>
              </div>
          </div>
        <br></br><br></br>
        <button className='btn btn-lg btn-light-pronto me-3' onClick={handleEndContract}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Passa a Deploying</h2>
        <div className="btn btn-sm btn-icon btn-active-color-pronto" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>La simulazione è passatta correttamente alla fase Deploying</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-pronto me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { PushToDeploying };