import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../helpers";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import { ICreateSimulation, createSimulationSchemas, inits } from "../../../../app/pages/gestione_simulazioni/Modifica_simulazione/CreateSimulationChecker";
import { editSimulation, simulationDetails } from "../../../../api/simulationApi";
import axios from "../../../../config/axiosConfig";

interface IOption {
  value: number;
  label: string;
}

type Props = {
  show: boolean;
  handleClose: () => void;
  simulationId: number;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const EditSimulation = ({ show, handleClose, simulationId }: Props) => {
  const [initValues, setInitValues] = useState<ICreateSimulation>(inits);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [responsabili, setResponsabili] = useState<IOption[]>([]);
  const [selectedResponsabile, setSelectedResponsabile] = useState<IOption | null>(null);

  // Funzione di conversione: trasforma eventuali valori null in stringhe vuote
  const convertNullsToStrings = (data: any): ICreateSimulation => {
    const newData: any = {};
    Object.keys(data).forEach((key) => {
      newData[key] = data[key] === null ? "" : data[key];
    });
    return newData as ICreateSimulation;
  };

  // Carica i dettagli della simulazione da modificare
  const fetchSimulationDetails = async (id: number) => {
    setLoading(true);
    try {
      const response = await simulationDetails(id);
      const simulationData = response.simulation as any;
      const newInit = convertNullsToStrings(simulationData);
      
      // Forza la conversione in Number se il valore esiste
      if (newInit.COD_Responsabile_sviluppo) {
        newInit.COD_Responsabile_sviluppo = Number(newInit.COD_Responsabile_sviluppo);
      }
      
      setInitValues(newInit);

      // Prova a recuperare l'ID del responsabile da uno dei due campi (COD_Responsabile_sviluppo o ID_Responsabile_sviluppo)
      const responsabileId = simulationData.COD_Responsabile_sviluppo || simulationData.ID_Responsabile_sviluppo;
      if (responsabileId) {
        setSelectedResponsabile({
          value: Number(responsabileId),
          label: `${simulationData.Responsabile_Nome} ${simulationData.Responsabile_Cognome}`,
        });
      }
    } catch (error) {
      console.error("Errore nel recupero dei dettagli della simulazione:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show && simulationId) {
      fetchSimulationDetails(simulationId);
    }
  }, [show, simulationId]);

  // Carica la lista dei responsabili (supervisori) per la simulazione
  useEffect(() => {
    const fetchResponsabili = async () => {
      try {
        const response = await axios.post("/api/developmentSupervisor/list");
        // Ordiniamo per nome
        const supervisors = response.data.sort((a: any, b: any) =>
          a.Nome.localeCompare(b.Nome)
        );
        const options = supervisors.map((item: any) => ({
          value: item.ID_Responsabile_sviluppo,
          label: `${item.Nome} ${item.Cognome}`,
        }));
        setResponsabili(options);
      } catch (error) {
        console.error("Errore nel recupero dei responsabili:", error);
      }
    };
    fetchResponsabili();
  }, []);

  // Effetto per sincronizzare il valore del Select se abbiamo un responsabile assegnato
  useEffect(() => {
    if (initValues.COD_Responsabile_sviluppo && responsabili.length > 0) {
      const found = responsabili.find(
        (option) => option.value === Number(initValues.COD_Responsabile_sviluppo)
      );
      if (found) {
        setSelectedResponsabile(found);
      } else {
        console.warn("Nessuna opzione trovata per il responsabile con ID:", initValues.COD_Responsabile_sviluppo);
      }
    }
  }, [initValues, responsabili]);

  // Funzione per inviare i dati modificati al backend
  const submitForm = async (
    values: ICreateSimulation,
    actions: FormikHelpers<ICreateSimulation>
  ) => {
    try {
      const response = await editSimulation(simulationId, values);
      if (response.status === 200) {
        setModalMessage("Simulazione modificata con successo!");
      } else {
        setModalMessage("Errore nella modifica della simulazione.");
      }
      actions.resetForm();
      // Chiudiamo il modal principale...
      handleClose();
      // ... e, dopo un breve ritardo, apriamo il modal di conferma
      setTimeout(() => {
        setShowConfirmModal(true);
      }, 300);
    } catch (error: any) {
      if (error.response) {
        setModalMessage(error.response.data);
      } else {
        setModalMessage("Errore durante la modifica della simulazione.");
      }
      actions.resetForm();
      handleClose();
      setTimeout(() => {
        setShowConfirmModal(true);
      }, 300);
    }
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  return createPortal(
    <>
      {/* Modal principale di EditSimulation */}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      >
        <div className="modal-header">
          <h2>Modifica Simulazione</h2>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>

        <div className="modal-body">
          <Formik
            initialValues={initValues}
            validationSchema={createSimulationSchemas}
            onSubmit={submitForm}
          >
            {({ setFieldValue }) => (
              <Form className="py-20 w-100 px-9" noValidate id="kt_edit_simulation_form">
                {/* Nome simulazione */}
                <div className="fv-row mb-10">
                  <label htmlFor="Nome" className="form-label required">
                    Nome
                  </label>
                  <Field
                    type="text"
                    id="Nome"
                    name="Nome"
                    className="form-control form-control-lg form-control-solid"
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="Nome" />
                  </div>
                </div>

                {/* Tipologia */}
                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center form-label mb-5 required">
                    Tipologia
                  </label>
                  <div className="row mb-2">
                    <div className="col">
                      <Field
                        type="radio"
                        className="btn-check"
                        name="Tipologia"
                        value="Pronto uso"
                        id="kt_tipologia_select_1"
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                        htmlFor="kt_tipologia_select_1"
                      >
                        <span className="fw-bolder fs-3">Pronto all'uso</span>
                      </label>
                    </div>
                    <div className="col">
                      <Field
                        type="radio"
                        className="btn-check"
                        name="Tipologia"
                        value="Personalizzato"
                        id="kt_tipologia_select_2"
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                        htmlFor="kt_tipologia_select_2"
                      >
                        <span className="fw-bolder fs-3">Personalizzato</span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* Modalità */}
                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center form-label mb-5 required">
                    Modalità
                  </label>
                  <div className="row mb-2">
                    <div className="col">
                      <Field
                        type="radio"
                        className="btn-check"
                        name="Modalita"
                        value="Individuale"
                        id="kt_modalita_select_1"
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                        htmlFor="kt_modalita_select_1"
                      >
                        <span className="fw-bolder fs-3">Individuale</span>
                      </label>
                    </div>
                    <div className="col">
                      <Field
                        type="radio"
                        className="btn-check"
                        name="Modalita"
                        value="Cooperativo"
                        id="kt_modalita_select_2"
                      />
                      <label
                        className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                        htmlFor="kt_modalita_select_2"
                      >
                        <span className="fw-bolder fs-3">Cooperativo</span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* Periodo consegna stimato */}
                <div className="fv-row mb-10">
                  <label htmlFor="Periodo_consegna_stimato" className="form-label required">
                    Periodo consegna stimato
                  </label>
                  <Field
                    type="text"
                    id="Periodo_consegna_stimato"
                    name="Periodo_consegna_stimato"
                    className="form-control form-control-lg form-control-solid"
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="Periodo_consegna_stimato" />
                  </div>
                </div>

                {/* Responsabile */}
                <div className="fv-row mb-10">
                  <label className="form-label required">Responsabile</label>
                  <Select
                    className="form-select-solid form-select-lg fw-bold"
                    value={selectedResponsabile}
                    placeholder="Scegli responsabile"
                    options={responsabili}
                    onChange={(selectedOption: IOption | null) => {
                      if (selectedOption) {
                        setSelectedResponsabile(selectedOption);
                        setFieldValue("COD_Responsabile_sviluppo", selectedOption.value);
                      } else {
                        setSelectedResponsabile(null);
                        setFieldValue("COD_Responsabile_sviluppo", null);
                      }
                    }}
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="COD_Responsabile_sviluppo" />
                  </div>
                </div>

                <div className="d-flex flex-stack pt-10">
                  <button type="submit" className="btn btn-lg btn-primary me-3">
                    Invia
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr064.svg"
                      className="svg-icon-3 ms-2 me-0"
                    />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      {/* Modal di conferma, separato dal modal principale */}
      <Modal
        id="kt_modal_confirm_edit"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        show={showConfirmModal}
        onHide={closeConfirmModal}
      >
        <div className="modal-header">
          <h2>Simulazione modificata correttamente</h2>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={closeConfirmModal}>
            <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
          </div>
        </div>
        <div className="modal-body">
          <p>{modalMessage}</p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={closeConfirmModal}>
            Chiudi
          </button>
        </div>
      </Modal>
    </>,
    modalsRoot
  );
};

export { EditSimulation };