/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../helpers";
import { simulationDetails } from "../../../../api/simulationApi"; // Funzione per ottenere i dettagli della simulazione
import dayjs from "dayjs";
import "dayjs/locale/it";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("it");

type Props = {
  show: boolean;
  handleClose: () => void;
  simulationId: number;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ViewSimulationDetails = ({ show, handleClose, simulationId }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>("details");
  const [simulation, setSimulation] = useState<any>(null);
  const [activeCustomers, setActiveCustomers] = useState<any[]>([]);

  useEffect(() => {
    if (show) {
      fetchSimulationDetails(simulationId);
      setSelectedTab("details");
    } else {
      setSelectedTab("details");
    }
  }, [show, simulationId]);

  const fetchSimulationDetails = async (id: number) => {
    try {
      const response = await simulationDetails(id);
      // Supponiamo che la risposta contenga "simulation" e "activeCustomers"
      const sim = response.simulation;
      setSimulation(sim);
      setActiveCustomers(response.activeCustomers);
    } catch (error) {
      console.error(error);
    }
  };

  return createPortal(
    <Modal
      id="kt_modal_view_details"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Dettagli Simulazione</h2>
        {/* Begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* End::Close */}
      </div>

      <div className="modal-body">
        <ul
          className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1"
          role="tablist"
        >
          <li className="nav-item mx-lg-1" role="presentation">
            <a
              className={`nav-link py-3 py-lg-6 ${
                selectedTab === "details" ? "text-active-primary" : ""
              }`}
              role="tab"
              onClick={() => setSelectedTab("details")}
            >
              Informazioni
            </a>
          </li>
          <li className="nav-item mx-lg-1" role="presentation">
            <a
              className={`nav-link py-3 py-lg-6 ${
                selectedTab === "acquistata" ? "text-active-primary" : ""
              }`}
              role="tab"
              onClick={() => setSelectedTab("acquistata")}
            >
              Acquistata da
            </a>
          </li>
        </ul>

        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          {simulation && selectedTab === "details" && (
            <div className="row">
              <div className="col-md-6">
                <div className="card-body p-9">
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Data inizio
                    </label>
                    <div className="col-lg-8 fv-row">
                      <span className="fw-bold fs-6">
                        nada
                      </span>
                    </div>
                  </div>
                  <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                      Data fine
                    </label>
                    <div className="col-lg-8 fv-row">
                      <span className="fw-bold fs-6">
                        nada
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedTab === "acquistata" && (
            <div className="row">
              <div className="col-md-12">
                <div className="card-body p-9">
                  {activeCustomers.length === 0 ? (
                    <span className="fw-bold fs-6">
                      Nessun cliente ha sottoscritto questa simulazione
                    </span>
                  ) : (
                    <ul>
                      {activeCustomers.map((customer, index) => (
                        <li key={index} className="fw-bold fs-6">
                          {customer.Ragione_sociale ||
                            `${customer.Nome} ${customer.Cognome}`}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { ViewSimulationDetails };