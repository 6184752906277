/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import Select from "react-select";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../helpers";
import { listCategory, transferGoggle } from "../../../../api/gogglesApi";
import axios from "../../../../config/axiosConfig";

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
};

export interface ICategory {
  ID_Categoria: number;
  Nome: string;
  Descrizione: string;
}

interface IOption {
  value: number;
  label: string;
}

const modalsRoot = document.getElementById("root-modals") || document.body;

const TransferGoggle = ({ show, handleClose, recordId }: Props) => {
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<IOption | null>(null);
  // Stato per memorizzare la categoria corrente pre-assegnata nel database
  const [currentCategory, setCurrentCategory] = useState<number | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Carica l'elenco delle categorie
  const fetchCategoryList = async () => {
    try {
      const response = await listCategory();
      setCategoryList(response);
    } catch (error) {
      console.error(error);
    }
  };

  // Carica i dettagli del goggle per ottenere la categoria corrente
  const fetchGoggleDetails = async () => {
    try {
      // Assumi che il tuo endpoint restituisca un oggetto con il campo "categoryId"
      const response = await axios.get(`/api/goggles/status/processing/goggleCategory/${recordId}`);
      setCurrentCategory(response.data[0].ID_Categoria);
    } catch (error) {
      console.error("Errore nel recupero della categoria del goggle:", error);
    }
  };

  // Quando il modal viene aperto, carica sia le categorie che i dettagli del goggle
  useEffect(() => {
    if (show) {
      fetchCategoryList();
      fetchGoggleDetails();
    }
  }, [show]);

  // Quando sono disponibili sia le categorie che il valore corrente, pre-seleziona l'opzione corretta
  useEffect(() => {
    if (currentCategory && categoryList.length > 0) {
      const cat = categoryList.find((c) => c.ID_Categoria === currentCategory);
      if (cat) {
        setSelectedCategoryId({ value: cat.ID_Categoria, label: cat.Nome });
      }
    }
  }, [currentCategory, categoryList]);

  const handleTransferGoggle = async () => {
    try {
      if (selectedCategoryId !== null) {
        const response = await transferGoggle(recordId, selectedCategoryId.value);
        handleClose();
        if (response.status === 200) {
          setModalMessage(response.data);
        }
        setShowModal(true);
      } else {
        alert("Per favore, seleziona una categoria.");
      }
    } catch (error: any) {
      console.error("Errore nella chiamata API:", error);
      if (error.response) {
        setModalMessage(error.response.data);
      } else if (error.request) {
        setModalMessage("Nessuna risposta dal server.");
      } else {
        setModalMessage("Errore nella richiesta.");
      }
      setShowModal(true);
    }
  };

  const closeConfirmModal = () => setShowModal(false);

  return createPortal(
    <>
      <Modal
        id="kt_modal_assign_goggles"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={show}
        onHide={handleClose}
      >
        <div className="modal-header">
          <h2>Cambio categoria</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-1"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body">
          <Select
            className="form-select-solid form-select-lg fw-bold"
            value={selectedCategoryId}
            placeholder="Scegli categoria"
            options={categoryList.map((categoria) => ({
              value: categoria.ID_Categoria,
              label: categoria.Nome,
            }))}
            onChange={(selectedOption: IOption | null) => {
              if (selectedOption) {
                setSelectedCategoryId(selectedOption);
              } else {
                setSelectedCategoryId(null);
              }
            }}
            isClearable={false}
          />
          <br />
          <button
            className="btn btn-lg btn-light-primary me-3"
            onClick={handleTransferGoggle}
          >
            Invio
          </button>
        </div>
      </Modal>
      <Modal
        id="kt_modal_confirm_assign"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-900px"
        show={showModal}
        onHide={closeConfirmModal}
      >
        <div className="modal-header">
          <h2>Cambio categoria</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={closeConfirmModal}
          >
            <KTSVG
              className="svg-icon-1"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body">
          <p>{modalMessage}</p>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-lg btn-light-primary me-3"
            onClick={closeConfirmModal}
          >
            Chiudi
          </button>
        </div>
      </Modal>
    </>,
    modalsRoot
  );
};

export { TransferGoggle };