import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikHelpers} from 'formik'
import {ICreateSimulation, createSimulationSchemas, inits} from './CreateSimulationChecker'
import {createSimulation} from '../../../../api/simulationApi'
import {Modal} from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select'
import axios from '../../../../config/axiosConfig'

interface IOption {
  value: number
  label: string
}

export interface IResponsabiliSviluppo {
  ID_Responsabile_sviluppo: number
  Nome: string
  Cognome: string
}

const InserisciSimulazione: React.FC = ({ }) => {
  const [initValues] = useState<ICreateSimulation>(inits)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('');

  const [responsabili, setResponsabili] = useState<IResponsabiliSviluppo[]>([]);
  const [selectedResponsabili, setSelectedResponsabili] = useState<IOption | null>(null);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const supervisors = await axios.post<IResponsabiliSviluppo[]>('/api/developmentSupervisor/list');
        const orderedSupervisors = supervisors.data.sort((a, b) => a.Nome.localeCompare(b.Nome));
        setResponsabili(orderedSupervisors);
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const submitForm = async (values: ICreateSimulation, actions: FormikHelpers<ICreateSimulation>) => {
    try {
      const response = await createSimulation(values)
      if (response.status === 201) {
        setModalMessage(response.data);
      }
      setShowModal(true);

      // Reset dei campi del form
      actions.resetForm();

      } catch (error: any) {
        if (error.response) {
          // La richiesta è stata effettuata e il server ha risposto con uno stato fuori dal range 2xx
          if (error.response.status === 400) {
            setModalMessage(error.response.data); //esiste già un visore con questi seriali
          }
          else if (error.response.status === 419) {
            setModalMessage(error.response.data); //almeno uno dei due seriali deve essere inserito
          }
        } else if (error.request) {
          // La richiesta è stata effettuata, ma non c'è stata alcuna risposta
          setModalMessage(error.response.data);
        } else {
          // Qualcosa è andato storto nella creazione della richiesta
          setModalMessage(error.response.data);
        }
        setShowModal(true);
      }
      actions.resetForm()
  }

  const closeModal = () => setShowModal(false)

  return (
    <div className="d-flex justify-content-center">
      <div className='d-flex flex-column bg-body rounded w-100' style={{ maxWidth: '700px' }}>
        <Formik initialValues={initValues} validationSchema={createSimulationSchemas} onSubmit={submitForm}>
          {({ setFieldValue }) => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>

              <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>Simulazione</h2>
                <div className='text-gray-400 fw-bold fs-6'>Inserisci i dati</div>
              </div>

              {/* Nome simulazione */}
              <div className='fv-row mb-10'>
                <label htmlFor='Nome' className='form-label required'>
                Nome
                </label>
                <Field
                  type='text'
                  id='Nome'
                  name='Nome'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Nome' />
                  </div>
              </div>

              {/* Tipologia */}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center form-label mb-5 required'>
                  Tipologia
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  {/*Radio button 1*/}
                  <div className='col'>
                    <Field
                      type='radio'
                      className='btn-check'
                      name='Tipologia'
                      value='Pronto uso'
                      id='kt_tipologia_select_1'
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                      htmlFor='kt_tipologia_select_1'
                    >
                      <span className='fw-bolder fs-3'>Pronto all'uso</span>
                    </label>
                  </div>

                  {/*Radio button 2*/}
                  <div className='col'>
                    <Field
                      type='radio'
                      className='btn-check'
                      name='Tipologia'
                      value='Personalizzato'
                      id='kt_tipologia_select_2'
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                      htmlFor='kt_tipologia_select_2'
                    >
                      <span className='fw-bolder fs-3'>Personalizzato</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Modalità */}
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center form-label mb-5 required'>
                  Modalità
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                  {/*Radio button 1*/}
                  <div className='col'>
                    <Field
                      type='radio'
                      className='btn-check'
                      name='Modalita'
                      value='Individuale'
                      id='kt_modalita_select_1'
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                      htmlFor='kt_modalita_select_1'
                    >
                      <span className='fw-bolder fs-3'>Individuale</span>
                    </label>
                  </div>

                  {/*Radio button 2*/}
                  <div className='col'>
                    <Field
                      type='radio'
                      className='btn-check'
                      name='Modalita'
                      value='Cooperativo'
                      id='kt_modalita_select_2'
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                      htmlFor='kt_modalita_select_2'
                    >
                      <span className='fw-bolder fs-3'>Cooperativo</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Periodo consegna stimato */}
              <div className='fv-row mb-10'>
                <label htmlFor='Periodo_consegna_stimato' className='form-label required'>
                Periodo consegna stimato
                </label>
                <Field
                  type='text'
                  id='Periodo_consegna_stimato'
                  name='Periodo_consegna_stimato'
                  className='form-control form-control-lg form-control-solid'
                />
          
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Periodo_consegna_stimato' />
                  </div>
              </div>

              {/* Responsabile */}
              <div className='fv-row mb-10'>
                <label className='form-label required'>
                  Responsabile
                </label>
                <Select
                  className='form-select-solid form-select-lg fw-bold'
                  value={selectedResponsabili}
                  placeholder="Scegli responsabile"
                  options={responsabili.map(responsabili => ({ value: responsabili.ID_Responsabile_sviluppo, label: `${responsabili.Nome} ${responsabili.Cognome}`  }))}
                  onChange={(selectedOption: IOption | null) => {
                    if (selectedOption) {
                      setSelectedResponsabili(selectedOption);
                      setFieldValue('COD_Responsabile_sviluppo', selectedOption.value);
                    } else {
                      setSelectedResponsabili(null);
                      setFieldValue('COD_Responsabile_sviluppo', null);
                    }
                  }}
                />

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='COD_Visore' />
                  </div>
              </div>
              
              <div className='d-flex flex-stack pt-10'>
                <button type='submit' className='btn btn-lg btn-primary me-3'>
                  Invia
                  <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Simulazione inserita correttamente</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={closeModal}>
              Chiudi
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export { InserisciSimulazione }
