/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from 'react';
import {saveAs} from 'file-saver'
import Papa from 'papaparse'
import { KTSVG, useDebounce } from '../../../helpers';
import { listUsers } from '../../../../api/usersApi';
import { UsersListSearchComponent } from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent';
import { UsersListPagination } from '../../../../app/modules/apps/user-management/users-list/components/pagination/UsersListPagination';
import { UsersListFilter } from './UsersListFilter';
import { EditUser } from '../../modals/edit-user/EditUser';
import { ChangePassword } from '../../modals/edit-user/ChangePassword';
import { DeleteUser } from '../../modals/delete-user/DeleteUser';
import { CreateUser } from '../../modals/create-user/CreateUser';
import {Buffer} from 'buffer'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/it';
import axios from 'axios';  

dayjs.extend(customParseFormat);

function getCutoffDate(lastLoginFilter: string): dayjs.Dayjs | null {
  const now = dayjs()
  switch (lastLoginFilter) {
    case 'Ieri':
      return now.subtract(1, 'day')
    case 'Ultima ora':
      return now.subtract(1, 'hour')
    case 'Ultime 5 ore':
      return now.subtract(5, 'hour')
    case 'Ultimi 2 giorni':
      return now.subtract(2, 'day')
    default:
      return null
  }
}

export interface IRoles {
  ID_Role: number
  Tipo: string
}

interface MyData {
  ID_User: string
  Foto_profilo: string
  Nome: string
  Email: string
  Ruolo: string
  Data_ultimo_accesso: string
}

type SortConfig = {
  key: keyof MyData;
  direction: 'asc' | 'desc';
};

type FilterState = {
  role?: string;
  lastLogin?: string;
}

type Props = {
  className: string;
};

const GestioneUtentiWidget: React.FC<Props> = ({ className }) => {
  // ---------------------------------------------------------
  // STATI E CONFIG INIZIALI
  // ---------------------------------------------------------
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'ID_User', direction: 'asc' });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [data, setData] = useState<MyData[]>([]);
  const [filteredData, setFilteredData] = useState<MyData[]>([]);

  // Stato per i FILTRI personalizzati (ruolo e last login)
  const [filter, setFilter] = useState<FilterState>({ role: '', lastLogin: '' });

  // Stato per i ruoli presi dal backend
  const [roles, setRoles] = useState<IRoles[]>([]);

  // Modali
  const [showModal, setShowModal] = useState(false);
  const [showEditModal2, setShowEditModal2] = useState(false);
  const [showEditModal3, setShowEditModal3] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);

  // ---------------------------------------------------------
  // EFFETTO: Recupera lista UTENTI
  // ---------------------------------------------------------
  const fetchUsers = async () => {
    try {
      const response = await listUsers();
      const formattedUsers = response.map((singleUser: any) => {
        // Converte data_ultimo_accesso in formato leggibile
        const dataUltimoAccesso = singleUser.Data_ultimo_accesso
          ? dayjs(singleUser.Data_ultimo_accesso).format('DD-MM-YYYY HH:mm:ss')
          : 'Nessun accesso ancora'
  
        // Se la foto è un buffer, convertilo in base64
        if (singleUser.Foto_profilo?.data) {
          const arrayBuffer = singleUser.Foto_profilo.data
          const base64String = Buffer.from(arrayBuffer).toString('base64')
          // Metti la data URL direttamente in singleUser.Foto_profilo
          // Se sai che è PNG, metti "image/png"; se è JPEG, "image/jpeg", ecc.
          singleUser.Foto_profilo = `data:image/png;base64,${base64String}`
        } else {
          // Se non c'è immagine, puoi assegnare una placeholder
          singleUser.Foto_profilo = '/media/avatars/blank_2.png'
        }
  
        return {
          ...singleUser,
          Data_ultimo_accesso: dataUltimoAccesso,
        }
      })
      
      setData(formattedUsers)
    } catch (error) {
      console.error('Errore nel recuperare gli utenti:', error)
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  // ---------------------------------------------------------
  // EFFETTO: Recupera lista RUOLI dal backend
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await axios.post<IRoles[]>('/api/users/listRoles'); 
        setRoles(res.data);
      } catch (error) {
        console.error('Errore nel recuperare i ruoli:', error);
      }
    };
    fetchRoles();
  }, []);

  // ---------------------------------------------------------
  // FILTRO DI TESTO (searchTerm) + FILTRO AVANZATO (role/lastLogin)
  // ---------------------------------------------------------
  useEffect(() => {
    let tempData = [...data]
  
    // 1) Filtro di testo (searchTerm)
    if (debouncedSearchTerm) {
      const lowerCasedSearchTerm = debouncedSearchTerm.toLowerCase()
      tempData = tempData.filter((user) => {
        return Object.values(user).some((val) => {
          if (val !== null && typeof val === 'string') {
            return val.toLowerCase().includes(lowerCasedSearchTerm)
          }
          return false
        })
      })
    }
  
    // 2) Filtro per role
    if (filter.role) {
      tempData = tempData.filter((user) => user.Ruolo === filter.role)
    }
  
    // 3) Filtro per lastLogin
    if (filter.lastLogin) {
      const cutoff = getCutoffDate(filter.lastLogin) // la funzione mostrata sopra
      if (cutoff) {
        tempData = tempData.filter((user) => {
          const userLastAccess = dayjs(user.Data_ultimo_accesso, 'DD-MM-YYYY HH:mm:ss')
          return userLastAccess.isAfter(cutoff)
        })
      }
    }
  
    // Ora abbiamo i dati filtrati
    setFilteredData(tempData)
  }, [debouncedSearchTerm, data, filter])
  

  // ---------------------------------------------------------
  // GESTIONE ORDINAMENTO
  // ---------------------------------------------------------
  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (sortConfig.key === 'ID_User') {
        const aValue = Number(a.ID_User) || 0;
        const bValue = Number(b.ID_User) || 0;
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  }, [filteredData, sortConfig]);

  // ---------------------------------------------------------
  // PAGINAZIONE
  // ---------------------------------------------------------
  const paginatedUsers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedData.slice(start, end);
  }, [sortedData, currentPage, itemsPerPage]);

  // ---------------------------------------------------------
  // FUNZIONI PER APERTURA/CLOSE MODALI
  // ---------------------------------------------------------
  const handleRowClick = (action: string, recordId: string) => {
    setSelectedRecordId(recordId);
    if (action === 'view-details') setShowModal(true);
    if (action === 'edit-user') setShowEditModal2(true);
    if (action === 'change-password') setShowEditModal3(true);
    if (action === 'delete-user') setShowDeleteModal(true);
    if (action === 'create-user') setShowCreateModal(true);
  };

  const handleRowClick2 = (action: string) => {
    if (action === 'create-user') {
      setShowCreateModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseEditModal2 = () => {
    setShowEditModal2(false);
  };
  const handleCloseEditModal3 = () => {
    setShowEditModal3(false);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  // ---------------------------------------------------------
  // FUNZIONE PER EXPORT CSV
  // ---------------------------------------------------------
  const exportDataToCSV = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Data.csv');
  };

  // ---------------------------------------------------------
  // FUNZIONE PER CAMBIARE ORDINAMENTO
  // ---------------------------------------------------------
  const changeSort = (key: keyof MyData) => {
    setSortConfig((currentSortConfig) => {
      if (currentSortConfig.key === key) {
        return {
          key,
          direction: currentSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };

  // ---------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------
  return (
    <div className={`card ${className}`}>
      {/* begin::Barra di ricerca */}
      <div className='card-header border-0 pt-6'>
        <UsersListSearchComponent setParentSearchTerm={setSearchTerm} />
        
        {/* Pulsanti a destra */}
        <div className='card-toolbar d-flex align-items-center'>
          {/* UsersListFilter -> Gli passo i ruoli e una callback per impostare il filtro */}
          <UsersListFilter 
            roles={roles} 
            onFilterChange={(newFilter) => {
              // Esempio: { role: 'admin', lastLogin: 'Yesterday' }
              setFilter(newFilter);
            }}
          />
          
          <button 
            type='button' 
            className='btn btn-light-primary me-3' 
            onClick={exportDataToCSV}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Export
          </button>
          
          <a
            onClick={() => handleRowClick2('create-user')}
            className='btn btn-primary'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Clicca per aggiungere un utente'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nuovo utente
          </a>
        </div>
      </div>
      {/* end::Barra di ricerca */}

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Gestione utenti</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px' onClick={() => changeSort('Nome')}>UTENTE</th>
                <th className='min-w-140px' onClick={() => changeSort('Ruolo')}>RUOLO</th>
                <th className='min-w-140px' onClick={() => changeSort('Email')}>EMAIL</th>
                <th className='min-w-120px' onClick={() => changeSort('Data_ultimo_accesso')}>ULTIMO ACCESSO</th>
                <th className='min-w-100px text-end'>AZIONI</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {paginatedUsers.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                      <img src={user.Foto_profilo} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {user.Nome}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      className={`badge fw-semibold fs-7 ${
                        user.Ruolo === 'dipendente' ? 'badge-primary' :
                        user.Ruolo === 'admin' ? 'badge-info' :
                        user.Ruolo === 'socio' ? 'badge-success' : 'badge-secondary'
                      }`}
                    >
                      {user.Ruolo}
                    </span>
                  </td>
                  <td className='text-start'>
                    <span className='text-muted fw-semibold fs-7 text-body'>{user.Email}</span>
                  </td>
                  <td className='text-start'>
                    <span className='text-muted fw-semibold fs-7 text-body'>{user.Data_ultimo_accesso}</span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        onClick={() => handleRowClick('change-password', user.ID_User)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/others/key.svg' className='svg-icon-3' />
                      </a>
                      <a
                        onClick={() => handleRowClick('edit-user', user.ID_User)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>
                      <a
                        onClick={() => handleRowClick('delete-user', user.ID_User)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {/* Pagina paginazione */}
        <UsersListPagination
          totalItems={filteredData.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
      {/* end::Body */}
      
      {selectedRecordId !== null && (
        <>
          <EditUser show={showEditModal2} handleClose={handleCloseEditModal2} recordId={selectedRecordId}/>
          <ChangePassword show={showEditModal3} handleClose={handleCloseEditModal3} recordId={selectedRecordId}/>
          <DeleteUser show={showDeleteModal} handleClose={handleCloseDeleteModal} recordId={selectedRecordId}/>
          <CreateUser show={showCreateModal} handleClose={handleCloseCreateModal}/>
        </>
      )}
      {(
        <>
          <CreateUser show={showCreateModal} handleClose={handleCloseCreateModal}/>
        </>
      )}
    </div>
  );
};

export { GestioneUtentiWidget };
