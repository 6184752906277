import {useEffect, useRef, useState, useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4_2} from './steps/Step4_2'
import {Step5_2} from './steps/Step5_2'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikHelpers} from 'formik'
import {ICreateOrder, createOrderSchemas, inits} from './CreateOrderChecker-Esistenti'
import {createOrder} from '../../../../api/ordersApi'
import { IClient } from './ExistingClientSelector'
import {Modal} from 'react-bootstrap';

interface IProps {
  client: IClient;
}

const InserisciOrdineClientiEsistenti: React.FC<IProps> = ({client}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentStepIndex, setCurrentStepIndex] = useState(2);
  const [initValues] = useState<ICreateOrder>(inits)
  const [showModal, setShowModal] = useState(false);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (stepper.current) {
      setCurrentStepIndex(stepper.current.currentStepIndex);
    }
  }, [stepper.current?.currentStepIndex]);

  useEffect(() => {
    const stepperElement = stepperRef.current;

    if (stepperElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'class') {
            // Assumi che la classe 'current' indichi lo step attuale
            // e che gli step siano ordinati correttamente nel DOM
            const steps = stepperElement.querySelectorAll('[data-kt-stepper-element="nav"]');
            steps.forEach((step, index) => {
              if (step.classList.contains('current')) {
                setCurrentStepIndex(index + 1);
              }
            });
          }
        });
      });

      observer.observe(stepperElement, {
        attributes: true,
        subtree: true,
        attributeFilter: ['class']
      });

      return () => observer.disconnect();
    }
  }, []);

  const submitStep = async (values: ICreateOrder, ragioneSociale: string, actions: FormikHelpers<ICreateOrder>) => {

    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {

      // Fai la chiamata Axios qui per inviare i dati del form
      try {
        values.Ragione_sociale = ragioneSociale;
        const response = await createOrder(values);
        if (response.status === 201) {
          // Gestisci la risposta di successo, ad esempio mostrando un messaggio di conferma
          setShowModal(true); // Mostra il modal
        } else {
          // Gestisci eventuali errori nella risposta
          console.error('Errore nella creazione dell\'ordine');
        }
      } catch (error) {
        // Gestisci errori di rete o altri errori imprevisti
        console.error('Errore nella chiamata API:', error);
      }
  
      stepper.current.goto(2);
      actions.resetForm();
    }
  };

  const closeModal = () => setShowModal(false);

  // Logica per determinare lo schema di validazione basato su currentStepIndex
  const validationSchema = useMemo(() => createOrderSchemas(currentStepIndex), [currentStepIndex]);

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Prodotti</h3>
                  <div className='stepper-desc fw-semibold'>Step 1</div>
                </div>
              </div>
              <div className='stepper-line h-40px'></div>
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Servizi</h3>
                  <div className='stepper-desc fw-semibold'>Step 2</div>
                </div>
              </div>
              <div className='stepper-line h-40px'></div>
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Indirizzo di spedizione</h3>
                  <div className='stepper-desc fw-semibold'>Step 3</div>
                </div>
              </div>
              <div className='stepper-line h-40px'></div>
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Fatturazione</h3>
                  <div className='stepper-desc fw-semibold'>Step 4</div>
                </div>
              </div>
            </div>
            {/* end::Step 4*/}
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
      <Formik initialValues={initValues} validationSchema={validationSchema} onSubmit={(values, actions) => submitStep(values, client.Ragione_sociale, actions)}>
        
        {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              
              {/*<div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>*/}

              <div className='current' data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4_2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5_2 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Indietro
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totatStepsNumber! - 0 && 'Continua'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 0 && 'Invia'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ordine Creato</Modal.Title>
        </Modal.Header>
        <Modal.Body>L'ordine è stato creato con successo!</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={closeModal}>
            Chiudi
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {InserisciOrdineClientiEsistenti}