import * as Yup from 'yup'
import { validationConfig5 } from '../../../../validationConfig'

export interface ICreateSimulation {
  Nome: string
  Tipologia: string
  Modalita: string
  Periodo_consegna_stimato: string
  COD_Responsabile_sviluppo: number | null
}

export const createSimulationSchemas = Yup.object().shape({
  Nome: validationConfig5.Nome.required
    ? Yup.string().required('Nome obbligatorio').label('Nome')
    : Yup.string().label('Nome'),
  Tipologia: Yup.string()
    .oneOf(['Pronto uso', 'Personalizzato'], 'Tipologia non valida')
    .required('Tipologia obbligatoria')
    .label('Tipologia'),
  Modalita: Yup.string()
    .oneOf(['Individuale', 'Cooperativo'], 'Modalità non valida')
    .required('Modalità obbligatoria')
    .label('Modalità'),
  Periodo_consegna_stimato: validationConfig5.Periodo_consegna_stimato.required
    ? Yup.string().required('Periodo consegna stimato obbligatorio').label('Periodo consegna stimato')
    : Yup.string().label('Periodo consegna stimato'),
  COD_Responsabile_sviluppo: Yup.number()
    .typeError('Responsabile deve essere un numero')
    .required('Responsabile obbligatorio')
    .label('Responsabile'),
})

export const inits: ICreateSimulation = {
  Nome: '',
  Tipologia: '',
  Modalita: '',
  Periodo_consegna_stimato: '',
  COD_Responsabile_sviluppo: null,
}