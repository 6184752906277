import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../assets/ts/components/MenuComponent'
import {KTSVG} from '../../../helpers'

export interface IRoles {
  ID_Role: number
  Tipo: string
}

type FilterProps = {
  roles: IRoles[];  // i ruoli da mostrare nel <select>
  onFilterChange: (filter: {role?: string, lastLogin?: string}) => void;
};

const UsersListFilter = ({roles, onFilterChange}: FilterProps) => {
  const [role, setRole] = useState<string>('');
  const [lastLogin, setLastLogin] = useState<string>('');

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const handleApply = () => {
    // Comunichiamo al "padre" i valori del filtro
    onFilterChange({
      role,
      lastLogin,
    });
  };

  const handleReset = () => {
    // Pulisco i campi e comunico {role:'', lastLogin:''} al padre
    setRole('');
    setLastLogin('');
    onFilterChange({
      role: '',
      lastLogin: '',
    });
  };

  return (
    <>
      {/* begin::Filter Button */}
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}

      {/* begin::SubMenu */}
      <div 
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
      >
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>

          {/* begin::Input group - ROLES */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Ruolo:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value=''>Tutti</option>
              {roles.map((r) => (
                // "r.Tipo" è la stringa da usare come value
                <option key={r.ID_Role} value={r.Tipo}>
                  {r.Tipo}
                </option>
              ))}
            </select>
          </div>
          {/* end::Input group - ROLES */}

          {/* begin::Input group - LAST LOGIN */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Ultimo accesso:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={lastLogin}
              onChange={(e) => setLastLogin(e.target.value)}
            >
              <option value=''>Tutti</option>
              <option value='Ieri'>Ieri</option>
              <option value='Ultima ora'>Ultima ora</option>
              <option value='Ultime 5 ore'>Ultime 5 ore</option>
              <option value='Ultimi 2 giorni'>Ultimi 2 giorni</option>
            </select>
          </div>
          {/* end::Input group - LAST LOGIN */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={handleReset}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
            >
              Reset
            </button>
            <button
              type='button'
              onClick={handleApply}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}

        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  );
};

export {UsersListFilter};
