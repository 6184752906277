import axios from '../config/axiosConfig';

export async function getUserByToken(token) {
  try {
    const response = await axios.get(`/api/auth/user/${token}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Impossibile ottenere le informazioni sull\'utente');
  }
}

/*export async function getUserDetailsByToken(COD_User) {
  try {
    console.log("Controllo COD_User", COD_User);
    const response = await axios.get(`/api/auth/userDetails/${COD_User}`);
    console.log("Controllo response", response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Impossibile ottenere i dettagli dell\'utente');
  }
}*/