import axios from '../config/axiosConfig';

/* Creazione nuova simulazione */
export const createSimulation = async (simulation) => {
  try {
    const response = await axios.post('/api/simulations/create', simulation);
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error creating simulation:', error);
    throw error;
  }
};

/* Ottieni la lista di tutte le simulazioni, sia quelle vendibili che quelle in fase di sviluppo */
export const listAll = async () => {
  try {
    const response = await axios.post('/api/simulations/listAll');
    return response.data;
  } catch (error) {
    console.error('Error fetching all simulations in db: ', error);
    throw error;
  }
};

/* Ottieni la lista di tutte le simulazioni vendibili a catalogo */
export const salableSimulations = async () => {
  try {
    const response = await axios.post('/api/simulations/listSalable');
    return response.data;
  } catch (error) {
    console.error('Error fetching salable simulations: ', error);
    throw error;
  }
};

/* Dettagli simulazione */
export const simulationDetails = async (id) => {
  try {
    const response = await axios.get(`/api/simulations/status/processing/details/${id}`);
    return response.data;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error fetching status simulations: details', error);
    throw error;
  }
};

/* Passa la simulazione allo step Montaggio */
export const pushToMontaggio = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToMontaggio/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to montaggio: ', error);
    throw error;
  }
};

/* Passa la simulazione allo step Sviluppo */
export const pushToSviluppo = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToSviluppo/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to sviluppo: ', error);
    throw error;
  }
};

/* Passa la simulazione allo step Testing */
export const pushToTesting = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToTesting/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to testing: ', error);
    throw error;
  }
};

/* Passa la simulazione allo step Deploying */
export const pushToDeploying = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToDeploying/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to deploying: ', error);
    throw error;
  }
};

/* Passa la simulazione allo step Vendibile */
export const pushToVendibile = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToVendibile/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to vendibile: ', error);
    throw error;
  }
};

/* Passa la simulazione allo step Manutenzione */
export const pushToManutenzione = async (simulationId) => {
  try {
    const response = await axios.put(`/api/simulations/pushToManutenzione/${simulationId}`);
    return response.data;
  } catch (error) {
    console.error('Error pushing simulation to manutenzione: ', error);
    throw error;
  }
};

/* Modifica simulazione */
export const editSimulation = async (simulationId, simulationData) => {
  try {
    const response = await axios.put(`/api/simulations/edit/${simulationId}`, { simulationData });
    return response;
  } catch (error) {
    // Gestisci l'errore come preferisci, ad esempio mostrando un messaggio all'utente
    console.error('Error editing simulation:', error);
    throw error;
  }
};

