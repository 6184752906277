import * as Yup from 'yup'
import {validationConfig} from '../../../../validationConfig';

export interface ICreateOrder {
    Ragione_sociale: string
    P_IVA: string
    CF: string
    SDI: string
    PEC: string
    Email: string
    IntermediarioRadio: string
    Intermediario: string
    Modello_visore: number[]
    Piano_abbonamento: string
    Lunghezza_abbonamento: number
    Simulazioni_scelte: string[]
    Opzioni_aggiuntive_scelte: string[]
    Importo_effettivo: number
    Note_aggiuntive: string
    Indirizzo_sped: string
    Appartamento_Interno_Scala_sped: string
    Citta_sped: string
    Provincia_sped: string
    CAP_sped: string
    IndirizzoSpedizioneDiversoDaFatturazione: boolean
    Indirizzo_fatt: string
    Appartamento_Interno_Scala_fatt: string
    Citta_fatt: string
    Provincia_fatt: string
    CAP_fatt: string
    Data_entrata_ordine: Date | null
    Data_inizio_contratto: Date | null
    Tipologia_cessione: string
    Metodo_pagamento: string
    Modalita_pagamento: string
}

export const createOrderSchemas = (currentStep:any) => {
  let schema = {};

  switch (currentStep) {
    case 2:
      schema = {
        Modello_visore: Yup.array()
          .of(Yup.number())
          .min(1, 'Devi selezionare almeno un modello di visore')
          .required('Modello visore obbligatorio')
          .label('Modello_visore'),
      };
      break;
    case 3:
      schema = {
        Piano_abbonamento: validationConfig.Piano_abbonamento.required
          ? Yup.string().required('Piano abbonamento obbligatorio').label('Piano_abbonamento')
          : Yup.string().label('Piano_abbonamento'),
        Lunghezza_abbonamento: validationConfig.Lunghezza_abbonamento.required
          ? Yup.number().oneOf([3, 6, 9, 12, 13, 14, 15, 16], 'La lunghezza dell\'abbonamento deve essere 3, 6, 9, 12, 13, 14, 15 o 16 mesi').required('Lunghezza abbonamento obbligatoria').label('Lunghezza_abbonamento')
          : Yup.number().oneOf([3, 6, 9, 12, 13, 14, 15, 16], 'La lunghezza dell\'abbonamento deve essere 3, 6, 9, 12, 13, 14, 15 o 16 mesi').label('Lunghezza_abbonamento'),
        Simulazioni_scelte: Yup.array()
        .of(Yup.string())
        .when('Piano_abbonamento', {
          is: 'Immersive',
          then: Yup.array()
            .of(Yup.string())
            .min(1, 'Simulazioni scelte obbligatorie')
            .max(6, 'Puoi selezionare solo 6 simulazioni con il piano Immersive') // validazione per "Immersive"
            .label('Simulazioni_scelte'),
          otherwise: Yup.array().of(Yup.string()).when('Piano_abbonamento', {
            is: 'Kickstart',
            then: Yup.array()
              .of(Yup.string())
              .min(1, 'Simulazioni scelte obbligatorie')
              .max(2, 'Puoi selezionare solo 2 simulazioni con il piano Kickstart') // validazione per "Kickstart"
              .label('Simulazioni_scelte'),
            otherwise: Yup.array().of(Yup.string()).when('Piano_abbonamento', {
              is: 'DiveVR',
              then: Yup.array()
                .of(Yup.string())
                .min(1, 'Simulazioni scelte obbligatorie')
                .max(3, 'Puoi selezionare solo 3 simulazioni con il piano DiveVR') // validazione per "DiveVR"
                .label('Simulazioni_scelte'),
              otherwise: Yup.array()
                .of(Yup.string())
                .min(1, 'Simulazioni scelte obbligatorie') // validazione per altri piani (Matrix)
                .label('Simulazioni_scelte')
            }),
          }),
        }),
        Opzioni_aggiuntive_scelte: validationConfig.Opzioni_aggiuntive_scelte.required
          ? Yup.array().of(Yup.string()).label('Opzioni_aggiuntive_scelte')
          : Yup.string().label('Opzioni_aggiuntive_scelte'),
        Importo_effettivo: validationConfig.Importo_effettivo.required
          ? Yup.number().min(1, 'L\'importo minimo è 1€').required('Importo effettivo obbligatorio').label('Importo_effettivo')
          : Yup.number().min(1, 'L\'importo minimo è 1€').label('Importo_effettivo'),
      };
    break;
    case 4:
      schema = {
        Indirizzo_sped: validationConfig.Indirizzo_sped.required
          ? Yup.string().required('Indirizzo di spedizione obbligatorio').label('Indirizzo_sped')
          : Yup.string().label('Indirizzo_sped'),
        Appartamento_Interno_Scala_sped: validationConfig.Appartamento_Interno_Scala_sped.required
          ? Yup.string().required('Appartamento, Interno, scala di spedizione obbligatori').label('Appartamento_Interno_Scala_sped')
          : Yup.string().label('Appartamento_Interno_Scala_sped'),
        Citta_sped: validationConfig.Citta_sped.required
          ? Yup.string().required('Città di spedizione obbligatoria').label('Citta_sped')
          : Yup.string().label('Citta_sped'),
        Provincia_sped: validationConfig.Provincia_sped.required
          ? Yup.string().required('Provincia di spedizione obbligatoria').label('Provincia_sped')
          : Yup.string().label('Provincia_sped'),
        CAP_sped: validationConfig.CAP_sped.required
          ? Yup.string().required('CAP di spedizione obbligatorio').label('CAP_sped')
          : Yup.string().label('CAP_sped'),
        //Checkbox se tenere stesso indirizzo
        IndirizzoSpedizioneDiversoDaFatturazione: Yup.boolean(),
        //Indirizzo di fatturazione
        Indirizzo_fatt: Yup.string()
          .when('IndirizzoSpedizioneDiversoDaFatturazione', {
            is: true,
            then: Yup.string().required('Indirizzo di fatturazione richiesto').label('Indirizzo_fatt'),
            otherwise: Yup.string().notRequired()
        }),
        Appartamento_Interno_Scala_fatt: Yup.string()
          .when('IndirizzoSpedizioneDiversoDaFatturazione', {
            is: true,
            then: Yup.string().label('Appartamento_Interno_Scala_fatt'),
            otherwise: Yup.string().notRequired()
        }),
        Citta_fatt: Yup.string()
          .when('IndirizzoSpedizioneDiversoDaFatturazione', {
            is: true,
            then: Yup.string().required('Città di fatturazione obbligatoria').label('Citta_fatt'),
            otherwise: Yup.string().notRequired()
        }),
        Provincia_fatt: Yup.string()
          .when('IndirizzoSpedizioneDiversoDaFatturazione', {
            is: true,
            then: Yup.string().required('Provincia di fatturazione obbligatoria').label('Provincia_fatt'),
            otherwise: Yup.string().notRequired()
        }),
        CAP_fatt: Yup.string()
          .when('IndirizzoSpedizioneDiversoDaFatturazione', {
            is: true,
            then: Yup.string().required('CAP di fatturazione obbligatoria').label('CAP_fatt'),
            otherwise: Yup.string().notRequired()
        }),
      };
      break;
    case 5:
        schema = {
          Data_entrata_ordine: validationConfig.Data_entrata_ordine.required
            ? Yup.date().nullable().required('Data entrata ordine obbligatoria').max(new Date(), 'La data inserita non può essere successiva a quella odierna').label('Data_entrata_ordine')
            : Yup.string().nullable().label('Data_entrata_ordine'),
          Data_inizio_contratto: validationConfig.Data_inizio_contratto.required
            ? Yup.date().nullable().required('Data inizio contratto obbligatoria').max(new Date(), 'La data inserita non può essere successiva a quella odierna').label('Data_inizio_contratto')
            : Yup.string().nullable().label('Data_inizio_contratto'),
          Tipologia_cessione: validationConfig.Tipologia_cessione.required
            ? Yup.string().oneOf(['Noleggio','Vendita','Comprati loro']).required('Tipologia cessione obbligatoria').label('Tipologia_cessione')
            : Yup.string().label('Tipologia_cessione'),
          Metodo_pagamento: validationConfig.Metodo_pagamento.required
            ? Yup.string().oneOf(['Carta','Paypal','Bonifico','Ri.Ba','SDD','RID']).required('Metodo pagamento obbligatorio').label('Metodo_pagamento')
            : Yup.string().label('Metodo_pagamento'),
          Modalita_pagamento: validationConfig.Modalita_pagamento.required
            ? Yup.string().oneOf(['Unica soluzione','Mensile']).required('Modalità pagamento obbligatoria').label('Modalita_pagamento')
            : Yup.string().label('Modalita_pagamento'),
        };
      break;
  }
  return Yup.object().shape(schema);
};

export const inits: ICreateOrder = {
  Ragione_sociale: 'presa da backend',
  P_IVA: 'presa da backend',
  CF: 'presa da backend',
  SDI: 'presa da backend',
  PEC: 'presa da backend',
  Email: 'presa da backend',
  IntermediarioRadio: 'No',
  Intermediario: '',
  Modello_visore: [],
  Piano_abbonamento: '',
  Lunghezza_abbonamento: 0,
  Simulazioni_scelte: [],
  Opzioni_aggiuntive_scelte: [],
  Importo_effettivo: 0,
  Note_aggiuntive: '',
  Indirizzo_sped: '',
  Appartamento_Interno_Scala_sped: '',
  Citta_sped: '',
  Provincia_sped: '',
  CAP_sped: '',
  IndirizzoSpedizioneDiversoDaFatturazione: false,
  Indirizzo_fatt: '',
  Appartamento_Interno_Scala_fatt: '',
  Citta_fatt: '',
  Provincia_fatt: '',
  CAP_fatt: '',
  Data_entrata_ordine: null,
  Data_inizio_contratto: null,
  Tipologia_cessione: '',
  Metodo_pagamento: '',
  Modalita_pagamento: '',
}