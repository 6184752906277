/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react"
import Select from 'react-select'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import {listGoogles, assignGoggles} from '../../../../api/ordersApi'

type Props = {
  show: boolean;
  handleClose: () => void;
  recordId: number;
  numGoggles: number | null
  numGogglesAssigned: number | null
};

const modalsRoot = document.getElementById('root-modals') || document.body

const AssignGogglesToOrder = ({ show, handleClose, recordId, numGoggles, numGogglesAssigned }: Props) => {
  const [gogglesList, setGogglesList] = useState<any[]>([]);
  const [selectedGoggleIds, setSelectedGoggleIds] = useState<number[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (show) {
      fetchGogglesList();
    }
  }, [show]);

  const fetchGogglesList = async () => {
    try {
      const response = await listGoogles(recordId);
      setGogglesList(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssignGoggles = async () => {
    try {
      for(let i = 0; i < selectedGoggleIds.length; i++) {
        await assignGoggles(recordId, selectedGoggleIds[i]);
      }
  
      // Resetta i visori selezionati dopo l'assegnazione
      setSelectedGoggleIds([]);
  
      handleClose();
      setShowConfirmModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => setShowConfirmModal(false);

  return createPortal(
    <>
    <Modal
      id="kt_modal_assign_goggles"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={handleClose}
    >
      <div className="modal-header">
        <h2>Assegna i visori all'ordine</h2>
        {/* begin::Close */}
        <div className="btn btn-sm btn-icon btn-active-color-ricevuto" onClick={handleClose}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body">
        <Select
            isMulti
            className='form-select-solid form-select-lg fw-bold'
            value={selectedGoggleIds.map((id) => ({ value: id, label: gogglesList.find((goggle) => goggle.ID_Visore_in_stock === id)?.Seriale_visore }))}
            placeholder="Scegli visori"
			options={gogglesList.map((goggle) => ({ value: goggle.ID_Visore_in_stock, label: goggle.Seriale_visore }))}
            onChange={(selectedOptions:any) => {
              if (selectedOptions && Array.isArray(selectedOptions)) {
                if (numGoggles !== null && numGogglesAssigned !== null && selectedOptions.length <= numGoggles - numGogglesAssigned) {
                  setSelectedGoggleIds(selectedOptions.map((option: any) => option.value));
                } else {
                  if(numGoggles !== null && numGogglesAssigned !== null && (numGoggles - numGogglesAssigned === 0)) {
                    alert(`Tutti i visori di questo ordine sono stati assegnati`);
                  }
                  else {
                    alert(`Non puoi selezionare più di ${numGoggles !== null && numGogglesAssigned !== null ? numGoggles - numGogglesAssigned : 0} visori.`);
                  }
                }
              } else {
                setSelectedGoggleIds([]);
              }
            }}
        />
        <br></br>
        <button className='btn btn-lg btn-light-ricevuto me-3' onClick={handleAssignGoggles}>Invio</button>
      </div>
    </Modal>
    <Modal
      id="kt_modal_confirm_assign"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={showConfirmModal}
      onHide={closeConfirmModal}
    >
      <div className="modal-header">
        <h2>Assegnazione visori</h2>
        <div className="btn btn-sm btn-icon btn-active-color-ricevuto" onClick={closeConfirmModal}>
          <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
        </div>
      </div>
      <div className="modal-body">
        <p>L'assegnazione dei visori è andata a buon fine!</p>
      </div>
      <div className="modal-footer">
        <button className='btn btn-lg btn-light-ricevuto me-3' onClick={closeConfirmModal}>Chiudi</button>
      </div>
    </Modal>
    </>,
    modalsRoot
  );
};

export { AssignGogglesToOrder };