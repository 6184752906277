/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'
import { Buffer } from 'buffer'
import { userDetailsToEdit } from '../../../../api/usersApi'
import { editUser } from '../../../../api/usersApi'

type Props = {
  show: boolean
  handleClose: () => void
  recordId: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditUser = ({ show, handleClose, recordId }: Props) => {
  const [idUser, setIDUser] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [profileImagePreview, setProfileImagePreview] = useState<string>('') 
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  // Funzione per caricare i dati dell'utente di riferimento dal backend
  useEffect(() => {
    const fetchUserDetails = async (id: string) => {
      try {
        const response = await userDetailsToEdit(id)
        if (response && response.length > 0) {
          const userDetails = response[0]
          setIDUser(userDetails.ID_User)
          setName(userDetails.Nome)
          setSurname(userDetails.Cognome)
          setEmail(userDetails.Email)
  
          // Se Foto_profilo è un buffer con 'data' e 'type'
          if (userDetails.Foto_profilo?.data) {
            // userDetails.Foto_profilo.data è un array di byte
            const arrayBuffer = userDetails.Foto_profilo.data
  
            // Con Buffer.from() converti l'array di byte in un Buffer e poi in stringa Base64
            const base64String = Buffer.from(arrayBuffer).toString('base64')
  
            // Infine crei una data URL da usare per l'attributo src dell'immagine. Se sai che l'immagine è un PNG, usa image/png; altrimenti adatta
            setProfileImagePreview(`data:image/png;base64,${base64String}`)
          } else {
            setProfileImagePreview('/media/avatars/blank_2.png')
          }
        }
      } catch (error) {
        console.error('Errore nel recupero dei dettagli utente:', error)
      }
    }
  
    if (show && recordId) {
      fetchUserDetails(recordId)
    }
  }, [show, recordId])  

  /**
   * Gestione del caricamento di una nuova immagine di profilo.
   * Salviamo una preview locale e, se necessario, potremo convertire
   * in Base64 per l'invio al backend.
   */
  const MAX_FILE_SIZE = 300 * 1024 // 512 KB

  const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
  
    // Controllo dimensione massima
    if (file.size > MAX_FILE_SIZE) {
      alert('Il file è troppo grande! Dimensione massima: 300KB.')
      // oppure un toast, un messaggio sul form, etc.
      return
    }
  
    // Se passa il controllo, possiamo leggere il file
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setProfileImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }

  const handleEditUser = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      // Creiamo l'oggetto con i dati utente
      const userData: any = {
        ID_User: idUser,
        Nome: name,
        Cognome: surname,
        Email: email
      }

      // Se abbiamo un'anteprima caricata (nuova immagine) userData conterrà il Base64 
      // (escluso il prefisso "data:image/png;base64,").
      // Se non vuoi splittare la stringa e preferisci mandare tutto "data:image/png;base64,..." 
      // puoi passare `profileImagePreview` direttamente.
      if (profileImagePreview && profileImagePreview.startsWith('data:image')) {
        userData.Foto_profilo = profileImagePreview.split(',')[1] 
      }

      const response = await editUser(userData)
      handleClose() // Chiudiamo la modale principale

      if (response.status === 200) {
        setModalMessage(response.data)
      }

      setShowConfirmModal(true)
    } catch (error: any) {
      if (error.response) {
        // La richiesta ha avuto risposta ma con uno status code fuori dal range 2xx
        setModalMessage(error.response.data)
      } else if (error.request) {
        // La richiesta è stata fatta ma non è stata ricevuta risposta
        setModalMessage('Nessuna risposta dal server.')
      } else {
        // Altro tipo di errore
        setModalMessage('Errore durante la richiesta.')
      }
      setShowConfirmModal(true)
    }
  }

  const closeConfirmModal = () => setShowConfirmModal(false)

  return createPortal(
    <>
      <Modal
        id='kt_modal_update_delivery_effective'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-header'>
          <h2>Modifica utente</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body'>
          <form onSubmit={handleEditUser}>
            {/* Nome */}
            <div className='fv-row mb-10'>
              <label htmlFor='Nome' className='form-label required'>
                Nome
              </label>
              <input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            {/* Cognome */}
            <div className='fv-row mb-10'>
              <label htmlFor='Cognome' className='form-label required'>
                Cognome
              </label>
              <input
                type='text'
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            {/* Email */}
            <div className='fv-row mb-10'>
              <label htmlFor='Email' className='form-label required'>
                Email
              </label>
              <input
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            {/* Immagine di Profilo */}
            <div className='fv-row mb-10'>
              <label htmlFor='ProfileImage' className='form-label'>
                Immagine profilo
              </label>

              {/* Contenitore principale: anteprima a sinistra, colonna di testo+pulsante a destra */}
              <div className='d-flex align-items-start'>
                {/* Box anteprima (100x100) */}
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    border: '1px solid #ccc',
                    overflow: 'hidden',
                  }}
                >
                  {profileImagePreview && (
                    <img
                      src={profileImagePreview}
                      alt='Anteprima Profilo'
                      style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    />
                  )}
                </div>

                {/* Colonna di testo (in alto) e pulsante (in basso). Impostiamo un'altezza fissa per 
                    allinearli verticalmente in corrispondenza dei 100px dell'anteprima. */}
                <div
                  className='d-flex flex-column justify-content-between ms-8'
                  style={{ height: '100px' }}
                >
                  {/* Scritta un po’ spostata in basso con "mt-2" (margine-top) */}
                  <span className='text-muted fs-7 mt-2'>
                    *dimensioni massime 96 x 96 pixel e peso 300kb
                  </span>

                  {/* Pulsante in basso (larghezza auto, non si espande) */}
                  <label
                    htmlFor='uploadProfileImage'
                    className='btn btn-light btn-active-light-primary'
                    style={{ width: '40%' }} // oppure puoi usare la classe "w-auto"
                  >
                    Scegli file
                  </label>
                </div>
              </div>

              {/* input type="file" invisibile */}
              <input
                id='uploadProfileImage'
                type='file'
                accept='image/*'
                onChange={handleProfileImageChange}
                className='d-none'
              />
            </div>

            <button type='submit' className='btn btn-lg btn-light-primary me-3'>
              Invio
            </button>
          </form>
        </div>
      </Modal>

      {/* Modale di conferma/messaggio */}
      <Modal
        id='kt_modal_confirm_assign'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={showConfirmModal}
        onHide={closeConfirmModal}
      >
        <div className='modal-header'>
          <h2>Modifica utente</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeConfirmModal}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body'>
          <p>{modalMessage}</p>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-lg btn-light-primary me-3' onClick={closeConfirmModal}>
            Chiudi
          </button>
        </div>
      </Modal>
    </>,
    modalsRoot
  )
}

export { EditUser }